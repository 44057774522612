<template>
	<div class="min-h-full w-full relative">
		<BHLoading :show="loading" />
		<iframe class="w-full h-full" title="email" scrolling="no" style="border:0" />
		<template v-if="attachments && attachments.length">
			<div class="mt-5 attachments">
				<a-icon class="mr-2" type="paper-clip" />{{ attachments.length }} {{ attachments.length > 1 ?
					'attachments'
					: 'attachment' }}
			</div>
			<div class="mt-3" style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap:2em;">
				<BHLoading :show="downloadingFiles" />
				<div v-for="(file, fileI) in attachments" :key="file + fileI" class="dF aC jSB px-2 py-1"
					style="background-color: #F7F5F9; border: 1px solid #E6EAF0; border-radius: 5px;">
					<div>
						<div>{{ file.filename }}</div>
						<div style="color: #A3A7AC;">{{ fileSize(file.body ? file.body.size : file.size) }}</div>
					</div>
					<div class="ml-2">
						<a-icon @click="downloadFile(file, fileI)" type="download" class="download-button" />
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>

	import BHLoading from 'bh-mod/components/common/Loading'
	export default {
		props: ['html', 'attachments', 'msgId'],
		data() {
			return {
				loading: true,
				downloadingFiles: false
			}
		},
		components: {
			BHLoading
		},
		methods: {
			fileSize(size) {
				//byte size to kb, mb, gb
				let kb = size / 1024;
				let mb = kb / 1024;
				let gb = mb / 1024;
				if (gb > 1) {
					return Math.round(gb * 10) / 10 + ' GB';
				} else if (mb > 1) {
					return Math.round(mb * 10) / 10 + ' MB';
				} else if (kb > 1) {
					return Math.round(kb * 10) / 10 + ' KB';
				} else {
					return 1 + ' KB';
				}
			},
			downloadFile(file) {
				this.downloadingFiles = true
				let attachmentId = file.body ? file.body.attachmentId : file.id;
				this.$api.get(`/contacts/:instance/messages/${this.msgId}/files/${attachmentId}/download`, { responseType: 'arraybuffer' }).then(({ data }) => {
					var blob = new Blob([data], { type: file.mimeType });
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = url;
					a.download = file.filename;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
					this.downloadingFiles = false
				}).catch(err => {
					this.downloadingFiles = false
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while downloading attachment. Please try again!`));
					}
				})
			}
		},
		created() {
			this.loading = true
		},
		mounted() {
			window.onmessage = (e) => {
				if (e.data.type === 'email') {
					this.$emit('email', e.data.html)
				}
			}

			let iframe = this.$el.querySelector('iframe')
			let doc = iframe.contentDocument || iframe.contentWindow.document
			// write an html to this iframe
			let html = this.html
			html.replace('<\/body>', `<script>

	        window.onload = function(){
	            console.log('FULLY LOADED')
	            // window.parent.postMessage(document.body.scrollHeight, "*")
	        }
	        <\/script><\/body>`)

			doc.open()
			doc.write(html)
			doc.close()

			setTimeout(() => {

				if (doc.body.scrollHeight) {
					iframe.style.height = doc.body.scrollHeight + 'px'

				}
				this.loading = false
				setTimeout(() => {
					let dom = document.querySelector('.mailContent')
					if (!dom) dom = this.$el.closest('.hide-scrollbar')
					if (!dom) return
					dom.scrollTop = 0
				}, 100);
				setTimeout(() => {
					if (doc.body.scrollHeight) {
						iframe.style.height = doc.body.scrollHeight + 'px'
					}
				}, 1000);
			}, 1000);
		}
	}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.single-email {
	width: 100%;
	height: 100%;
	background-color: #FFF;
	border-radius: 4px;
	padding: 40px;
	overflow: scroll
}

.multiple-email {
	background-color: #FFF;
	padding: 40px;
}

.attachments {
	display: flex;
	align-items: center;
	text-align: center;
}

.attachments::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid #EAEEF3;
	margin-left: 1em;
}

.replyButton {
	fill: rgba(160, 170, 190, 0.6);
}

.replyButton:hover {
	fill: var(--orange);
	transition: fill .1s ease-in;
}

.download-button {
	font-size: 20px;
	color: currentColor;
	cursor: pointer;
	transition: color 0.2s ease-in-out;
}

.download-button:hover {
	color: var(--orange);
	transition: color 0.2s ease-in-out;
}
</style>
